import React from 'react';
import { Img } from 'react-image';
import Loader from '../Loader/Loader.js';

const ProjectPreview = (props) => {
    return (
        <React.Fragment>
            <div className="col-md-6 col-lg-3 py-1">
                <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                    <a href="/#" onClick={(e)=> {
                        e.preventDefault();
                        props.projectOnClickHandler(props.project);
                    }}>
                        <figure className="cc-effect">
                            <Img
                                src={props.project.thumbnail}
                                loader={<div className="mx-auto sznThumbnail" ><Loader size="medium"/></div>}
                                unloader={<img className="sznImg" src={process.env.PUBLIC_URL + '/assets/img/image-not-found.png'} alt="404"/>}
                                className="mx-auto sznThumbnail"
                                alt="thumbnail"
                            />
                            <figcaption>
                                <div>
                                    <div className="h4">&nbsp;{props.project.title} &nbsp;</div>
                                    <p>See Details</p>
                                </div>
                            </figcaption>
                        </figure>
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ProjectPreview;