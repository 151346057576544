import React from 'react';
import Data from './Data';

const About = () => {
    return (
        <>
            <div className="section" id="about">
                <div className="container">
                    <div className="card" data-aos="fade-up" data-aos-offset="10">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="card-body">
                                    <div className="about-me-block pb-2">
                                        {Data.info.hakkimda}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="card-body">
                                    <div className="h4 mt-0 title">Bilgilerim</div>
                                    <div className="row mt-3">
                                        <div className="col-sm-4">
                                            <strong >Github:</strong>
                                        </div>
                                        <div className="col-sm-8">
                                            <a href={Data.info.github} target="_blank" rel="noopener noreferrer" className="text-muted">
                                                {Data.info.github}
                                            </a>
                                        </div>
                                    </div>
                                    {!Data.info.hideTelefon &&
                                        <div className="row mt-3">
                                            <div className="col-sm-4">
                                                <strong>Telefon:</strong>
                                            </div>
                                            <div className="col-sm-8 text-muted">{Data.info.telefon}</div>
                                        </div>
                                    }
                                    <div className="row mt-3">
                                        <div className="col-sm-4">
                                            <strong>Email:</strong>
                                        </div>
                                        <div className="col-sm-8">
                                            <a href={'mailto:' + Data.info.email} data-type="mail" className="text-muted">
                                                {Data.info.email}
                                            </a>
                                        </div>
                                    </div>
                                    {!Data.info.hideAddress &&
                                        <div className="row mt-3">
                                            <div className="col-sm-4">
                                                <strong>Adres:</strong>
                                            </div>
                                            <div className="col-sm-8 text-muted">{Data.info.adres}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;