import React, { useState } from 'react';
import ProjectPreview from './Projects/ProjectPreview';
import { If } from 'react-if';
import ProjectDetailsPopup from './Projects/ProjectDetailsPopup';
import Data from './Data';
import ReactiveButton from 'reactive-button';
import { BsArrowBarRight } from 'react-icons/bs';
import { FaFolder, FaFolderOpen } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';

const Project = () => {
    const [state, setState] = useState({
        isProjectDetailsPopupOpen: false,
        selectedProject: null
    });
    
    var hashTag = 'Hepsi';
    
    if (window.location.hash.substr(1) === 'projeler#Hepsi') {
        hashTag = 'Hepsi';
    } else if (window.location.hash.substr(1) === 'projeler#kisisel') {
        hashTag = 'Kişisel';
    } else if (window.location.hash.substr(1) === 'projeler#sirket') {
        hashTag = 'Şirket';
    }

    const [selectedTag, setSelectedTag] = useState(hashTag);

    const projects = Data.projeler;

    const itemPerPage = 12;
    const [visible, setVisible] = useState(itemPerPage);

    const loadMore = () => {
        console.log(window.location.hash.substr(1));
        setVisible(visible + itemPerPage);
    }

    const projectOnClickHandler = (project) => {
        setState({
            ...state,
            selectedProject: project, 
            isProjectDetailsPopupOpen: true
        });
    }

    const closeProjectDetailsPopup = () => {
        setState({
            ...state,
            selectedProject: null, 
            isProjectDetailsPopupOpen: false
        });
    }

    const populateProjectPreview = () => {
        return projects.filter(project => selectedTag === 'Hepsi' || (selectedTag !== 'Hepsi' && project.tags.includes(selectedTag))).slice(0, visible).map((project, index) => (
            <React.Fragment key={index}>
                <ProjectPreview project={project} projectOnClickHandler={projectOnClickHandler}/>
            </React.Fragment>
        ));
    }

    const filterOnClickHandler = (tag) => {
        setSelectedTag(tag);
    }

    const iconFolder = <IconContext.Provider value={{ size: '13px'}}>
            <FaFolder style={{verticalAlign: 'sub'}}/>
        </IconContext.Provider>
        

    const iconFolderOpen = <IconContext.Provider value={{ size: '13px'}}>
            <FaFolderOpen style={{verticalAlign: 'sub'}}/>
        </IconContext.Provider>

    return (
        <>
            <div className="section" id="projects">
                <div className="container">
                    <div className="row">
                        <div className="mx-auto col-md-12">
                            <div className="h4 text-center mb-4 title">Projeler</div>
                            <div className="nav-align-center project-filter-navbar">
                                <ul className="nav nav-tabs justify-content-center" role="tablist">
                                    <li className="nav-item mx-1 my-2">
                                        <ReactiveButton
                                            width="120px"
                                            size="small"
                                            idleText={<span>{selectedTag === 'Hepsi' ? iconFolderOpen : iconFolder}  Hepsi</span>}
                                            outline={selectedTag === 'Hepsi' ? false : true }
                                            shadow
                                            onClick={() => filterOnClickHandler('Hepsi')}
                                            animation={false}
                                        />
                                    </li>
                                    <li className="nav-item mx-1 my-2">
                                        <ReactiveButton
                                            width="120px"
                                            size="small"
                                            idleText={<span>{selectedTag === 'Şirket' ? iconFolderOpen : iconFolder} Şirket</span>}
                                            outline={selectedTag === 'Şirket' ? false : true }
                                            shadow
                                            onClick={() => filterOnClickHandler('Şirket')}
                                            animation={false}
                                        />
                                    </li>
                                    <li className="nav-item mx-1 my-2">
                                        <ReactiveButton
                                            width="120px"
                                            size="small"
                                            idleText={<span>{selectedTag === 'Kişisel' ? iconFolderOpen : iconFolder} Kişisel</span>}
                                            outline={selectedTag === 'Kişisel' ? false : true }
                                            shadow
                                            onClick={() => filterOnClickHandler('Kişisel')}
                                            animation={false}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content gallery mt-5">
                        <div className="tab-pane active" id="web-development">
                            <div className="mx-auto">
                                <div className="row">
                                    {populateProjectPreview()}
                                    <div className={`col-md-12 text-center pt-4 ${projects.filter(project => selectedTag === 'Hepsi' || (selectedTag !== 'Hepsi' && project.tags.includes(selectedTag))).length <= visible  ? 'd-none' : ''}`}>
                                        <ReactiveButton
                                            idleText={<React.Fragment>Daha Fazlası...<BsArrowBarRight/></React.Fragment>}
                                            onClick={loadMore}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <If condition={state.isProjectDetailsPopupOpen && state.selectedProject !== null}>
                <ProjectDetailsPopup
                    project={state.selectedProject}
                    handleClose={closeProjectDetailsPopup}
                />
            </If>
        </>
    );
}

export default Project;