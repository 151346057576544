import React, {useState} from 'react';
import { Img } from 'react-image';
import ReactiveButton from 'reactive-button';
import Data from './Data';
import Typed from 'react-typed';
import clsx from "clsx";

const Profile = () => {
    const [buttonState, buttonSetState] = useState('idle');

    const onClickHandler = () => {
        buttonSetState('loading');
        setTimeout(() => {
            buttonSetState(buttonState);
        }, 2000);
    }

    return (
        <>
            <div className="profile-page">
                <div className="wrapper">
                    <div className="page-header page-header-small" filter-color="theme">
                        <div className="page-header-image" data-parallax="true" style={{backgroundImage: "url('assets/img/2.png')"}}/>
                        <div className="container">
                            <div className="content-center">
                                <div className="cc-profile-image">
                                    <a href="/">
                                        <Img
                                            src={process.env.PUBLIC_URL + '/assets/img/pp.jpg'}
                                            loader={<img src={process.env.PUBLIC_URL + '/assets/img/pp.jpg'} alt="Ömer Faruk İlhan" />}
                                            unloader={<img className="sznImg" src={process.env.PUBLIC_URL + '/assets/img/image-not-found.png'} alt="404"/>}
                                            alt={Data.info.isim}
                                        />
                                    </a>
                                </div>
                                <div className="h2 title">{Data.info.isim}</div>
                                <p className="category text-white">
                                    <Typed
                                        className="font-weight-light"
                                        strings={
                                            Data.info.taglines && Data.info.taglines.length ? Data.info.taglines : ['']
                                        }
                                        typeSpeed={60}
                                        backSpeed={30}
                                        smartBackspace={true}
                                        loop={true}
                                        style={{color: "antiquewhite"}}
                                    />
                                </p>
                                {Data.info.hideCv &&
                                <div className="mt-4 w-100 h-100">
                                    <a href={Data.info.cv}
                                       target="_blank"
                                       data-aos="zoom-in"
                                       data-aos-anchor="data-aos-anchor"
                                       rel="noopener noreferrer">
                                        <ReactiveButton 
                                            style={{fontSize: '0.8571em'}} 
                                            idleText="CV İNDİR"
                                            className="px-5 py-3 d-flex justify-content-center align-items-center w-100 h-100"
                                            buttonState={buttonState}
                                            onClick={onClickHandler}
                                            loadingText={'Loading'}
                                        />
                                    </a>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="section">
                            <div className="container">
                                <div className="button-container">
                                    {Data.sosyalMedyaLinkleri.map((socialLink, index) => (
                                        <a key={index} className={
                                            clsx(
                                                "btn btn-default btn-round btn-lg btn-icon center-this",
                                                socialLink.visibility === true ? "d-none" : null
                                            )}
                                           href={socialLink.link}
                                           target="_blank"
                                           title={socialLink.title}
                                           rel="noopener noreferrer">
                                            {socialLink.icon}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile;