import React from 'react';
import Data from './Data';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <div className="container text-center">
                    <div className="row mb-3">
                        <div className="col-sm-12 text-center">
                            <ul className="footer-liste">
                                {Data.sosyalMedyaLinkleri.map((socialLink, index) => (
                                    socialLink.visibility === false && <li key={index}>
                                        <a
                                            className={socialLink.classname}
                                            href={socialLink.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i
                                                className={socialLink.iconName}
                                            />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                </div>
                <div className="h4 title text-center text-muted">{Data.info.isim}</div>
                <div className="text-center text-muted">
                    <p>&copy; All rights reserved.</p>
                </div>
            </footer>
        </React.Fragment>
    );
}

export default Footer;
