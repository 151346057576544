import React, {useState} from 'react';
import Data from './Data';
import {BiColorFill} from 'react-icons/bi';
import ThemePickerPopup from './ThemePickerPopup/ThemePickerPopup';

const Header = () => {
    const [displayThemePicker, setDisplayThemePicker] = useState(false);

    const openThemePicker = (e) => {
        e.preventDefault();
        setDisplayThemePicker(true);
    };

    const closeThemePicker = () => {
        setDisplayThemePicker(false);
    };

    return (
        <>
            <header>
                <div className="profile-page sidebar-collapse">
                    <nav className="navbar navbar-expand-lg fixed-top navbar-transparent bg-primary"
                         color-on-scroll="300">
                        <div className="container">
                            <div className="navbar-translate">
                                <a className="navbar-brand" href="/ " rel="tooltip">{Data.info.isim}</a>
                                <div className="theme-picker-wrapper ml-auto pr-2 d-block d-lg-none">
                                    <a href="/#" className="" onClick={openThemePicker}>
                                        <button className="theme-picker"><BiColorFill/></button>
                                    </a>
                                </div>
                                <button className="navbar-toggler navbar-toggler" type="button" data-toggle="collapse"
                                        data-target="#navigation" aria-controls="navigation" aria-expanded="false"
                                        aria-label="Toggle navigation">
                                    <span className="navbar-toggler-bar bar1"/>
                                    <span className="navbar-toggler-bar bar2"/>
                                    <span className="navbar-toggler-bar bar3"/>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse justify-content-end" id="navigation">
                                <ul className="navbar-nav">
                                    <li className="nav-item"><a className="nav-link smooth-scroll"
                                                                href="#about">HAKKIMDA</a></li>
                                    <li className="nav-item"><a className="nav-link smooth-scroll"
                                                                href="#skill">YETENEKLER</a></li>
                                    <li className="nav-item"><a className="nav-link smooth-scroll"
                                                                href="#projects">PROJELER</a></li>
                                    <li className="nav-item"><a className="nav-link smooth-scroll"
                                                                href="#experience">TECRÜBELER</a></li>
                                    <li className="nav-item"><a className="nav-link smooth-scroll"
                                                                href="#education">EĞİTİM</a></li>
                                    <li className="nav-item"><a className="nav-link smooth-scroll"
                                                                href="#contact">İLETİŞİM</a></li>
                                    <li className="nav-item pl-lg-5 theme-picker-wrapper d-none d-lg-block">
                                        <a href="/#" className="nav-link" onClick={openThemePicker}>
                                            <button className="theme-picker"><BiColorFill/></button>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
            {
                displayThemePicker && <ThemePickerPopup handleClose={closeThemePicker}/>
            }
        </>
    )
        ;
}

export default Header;
