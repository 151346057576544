import React from "react";
import {
    FaBootstrap,
    FaGitAlt,
    FaGithub,
    FaInstagram,
    FaLaravel,
    FaNode,
    FaPython,
    FaReact,
    FaVuejs,
} from "react-icons/fa";
import {
    SiDotNet,
    SiCss3,
    SiJavascript,
    SiJquery,
    SiMysql,
    SiPhp,
} from "react-icons/si";
import { TiSocialLinkedinCircular } from "react-icons/ti";
import { RiFacebookCircleFill } from "react-icons/ri";
import { IconContext } from "react-icons/lib";
import { ImMail4 } from "react-icons/im";
import { GrGolang } from "react-icons/all";

const skillIconSize = "4rem";

const info = {
    isim: "Ömer Faruk İlhan",
    hakkimda: (
        <>
            <p className="quote-line">Yazılım | Elektrik | Elektronik</p>
            <p className="py-2">
                Merhaba, Ben Ömer Faruk İlhan. Bozok üniversitesi
                Elektrik-Elektronik Mühendisliğinden mezun oldum.
            </p>
            <p>
                Elektrik Elektronik Mühendisliğini okurken yazılımla ilgili hobi
                olarak araştırmalar yaptım ve yazılıma olan bu merakım sayesinde
                bu meslekte çalışmayı tercih ettim.
            </p>
        </>
    ),
    telefon: "+905511449497",
    linkedin: "https://www.linkedin.com/in/farukhan42",
    email: "filhann42@gmail.com",
    adres: "Pendik / İstanbul",
    universite: "Bozok Üniversitesi",
    github: "https://github.com/farukhann42",
    facebook: "",
    instagram: "https://www.instagram.com/farukhann42/",
    hideCv: true,
    hideTelefon: true,
    hideAddress: true,
    taglines: ["WEB GELİŞTİRİCİSİ", "ELEKTRİK ELEKTRONİK MÜHENDİSİ"],
    cv: "https://drive.google.com/file/d/1Q-2c28uIBp_hs70CASJ5k5hkSf8O-Sx5/view?usp=sharing",
    formspree: "https://formspree.io/f/xnqovrqn",
};

const yeteneklerim = [
    {
        title: "React.js",
        icon: (
            <IconContext.Provider
                value={{ size: skillIconSize, color: "#31B2FB" }}
            >
                <FaReact />
            </IconContext.Provider>
        ),
        visibility: false,
    },
    {
        title: "JavaScript",
        icon: (
            <IconContext.Provider
                value={{ size: skillIconSize, color: "#E1CC1B" }}
            >
                <SiJavascript />
            </IconContext.Provider>
        ),
        visibility: false,
    },
    {
        title: "CSS",
        icon: (
            <IconContext.Provider
                value={{ size: skillIconSize, color: "#2862E9" }}
            >
                <SiCss3 />
            </IconContext.Provider>
        ),
        visibility: false,
    },
    {
        title: "Bootstrap",
        icon: (
            <IconContext.Provider
                value={{ size: skillIconSize, color: "#533B78" }}
            >
                <FaBootstrap />
            </IconContext.Provider>
        ),
        visibility: false,
    },
    {
        title: "PHP",
        icon: (
            <IconContext.Provider
                value={{ size: skillIconSize, color: "#7377AD" }}
            >
                <SiPhp />
            </IconContext.Provider>
        ),
        visibility: false,
    },
    {
        title: "Laravel",
        icon: (
            <IconContext.Provider
                value={{ size: skillIconSize, color: "#FF2D20" }}
            >
                <FaLaravel />
            </IconContext.Provider>
        ),
        visibility: true,
    },
    {
        title: "Vue.js",
        icon: (
            <IconContext.Provider
                value={{ size: skillIconSize, color: "#42B983" }}
            >
                <FaVuejs />
            </IconContext.Provider>
        ),
        visibility: true,
    },
    {
        title: "Python (Flask)",
        icon: (
            <IconContext.Provider
                value={{ size: skillIconSize, color: "#42B983" }}
            >
                <FaPython />
            </IconContext.Provider>
        ),
        visibility: true,
    },
    {
        title: "Golang",
        icon: (
            <IconContext.Provider
                value={{ size: skillIconSize, color: "#42B983" }}
            >
                <GrGolang />
            </IconContext.Provider>
        ),
        visibility: true,
    },
    {
        title: "Node.js",
        icon: (
            <IconContext.Provider
                value={{ size: skillIconSize, color: "#5C9553" }}
            >
                <FaNode />
            </IconContext.Provider>
        ),
        visibility: true,
    },
    {
        title: "jQuery",
        icon: (
            <IconContext.Provider
                value={{ size: skillIconSize, color: "#0769AD" }}
            >
                <SiJquery />
            </IconContext.Provider>
        ),
        visibility: false,
    },
    {
        title: "MySQL",
        icon: (
            <IconContext.Provider
                value={{ size: skillIconSize, color: "#4479A1" }}
            >
                <SiMysql />
            </IconContext.Provider>
        ),
        visibility: false,
    },
    {
        title: "Git",
        icon: (
            <IconContext.Provider
                value={{ size: skillIconSize, color: "#F44D27" }}
            >
                <FaGitAlt />
            </IconContext.Provider>
        ),
        visibility: true,
    },
    {
        title: "C#",
        icon: (
            <IconContext.Provider
                value={{ size: skillIconSize, color: "#9568CE" }}
            >
                <SiDotNet />
            </IconContext.Provider>
        ),
        visibility: false,
    },
];

const projeler = [
    {
        title: "Ömer Faruk İlhan",
        thumbnail:
            process.env.PUBLIC_URL + "/assets/img/projects/website/1.png",
        images: [
            process.env.PUBLIC_URL + "/assets/img/projects/website/1.png",
            process.env.PUBLIC_URL + "/assets/img/projects/website/2.png",
        ],
        skills: ["React.js"],
        tags: ["Hepsi", "Kişisel"],
        description: <>CV Web Sitesi</>,
        links: [
            {
                name: "Anasayfa",
                link: "https://farukhan.com/",
            },
        ],
    },
    {
        title: "Naos Web Sitesi",
        thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/naos/1.png",
        images: [
            process.env.PUBLIC_URL + "/assets/img/projects/naos/1.png",
            process.env.PUBLIC_URL + "/assets/img/projects/naos/2.png",
            process.env.PUBLIC_URL + "/assets/img/projects/naos/3.png",
        ],
        skills: ["Laravel", "React.js", "Mysql"],
        tags: ["Hepsi", "Şirket"],
        description: <>Naos & Bioderma & Esthederm</>,
        links: [
            {
                name: "Anasayfa",
                link: "https://naosbel.1pagecommerce.com/",
            },
        ],
    },
    {
        title: "Sürücü Kursu Projesi",
        thumbnail:
            process.env.PUBLIC_URL + "/assets/img/projects/surucu/surucu1.png",
        images: [
            process.env.PUBLIC_URL + "/assets/img/projects/surucu/surucu2.png",
            process.env.PUBLIC_URL + "/assets/img/projects/surucu/surucu3.png",
            process.env.PUBLIC_URL + "/assets/img/projects/surucu/surucu4.png",
            process.env.PUBLIC_URL + "/assets/img/projects/surucu/surucu5.png",
        ],
        skills: ["React Js", "Laravel", "Mysql"],
        tags: ["Hepsi", "Şirket"],
        description: (
            <>Sürücü Kursları için geliştirilen bir eğitim platformu</>
        ),
        links: [
            {
                name: "Anasayfa",
                link: "https://esutr.com/login",
            },
        ],
    },
    {
        title: "Todo Projesi",
        thumbnail: process.env.PUBLIC_URL + "/assets/img/projects/todo/1.png",
        images: [
            process.env.PUBLIC_URL + "/assets/img/projects/todo/1.png",
            process.env.PUBLIC_URL + "/assets/img/projects/todo/2.png",
            process.env.PUBLIC_URL + "/assets/img/projects/todo/3.png",
        ],
        skills: ["Html", "Css", "Javascript", "JQuery", "Ajax", "Php", "Mysql"],
        tags: ["Hepsi", "Kişisel"],
        description: (
            <>
                Todo Projesinde Php İle Anlık Veritabanına Kayıt Yapılıyor Ve
                Listeleniyor
            </>
        ),
        links: [
            {
                name: "Github",
                link: "https://github.com/farukhann42/todo",
            },
        ],
    },
];

const tecrubeler = [
    {
        company: "Albarakatech Global",
        from: "Şubat 2021",
        to: "Şu Anda",
        position: "Junior Software Developer",
        responsibilities: [
            "C#.Net / MsSQL ile Web Geliştirme",
            "Bakım - Destek",
        ],
    },
    {
        company: "PhiMajor Solutions",
        from: "Aralık 2020",
        to: "Şubat 2021",
        position: "Software Development Specialist",
        responsibilities: [
            "Typescript / React Js İle Web Geliştirme",
            "Microsoft SharePoint Kullanımı",
            "Teknoloji Geliştirmeleri (Yazılım, Bakım-Destek, Servis vb.)",
        ],
    },
    {
        company: "HubShou",
        from: "Ekim 2020",
        to: "Şu Anda",
        position: "Front-End Developer",
        responsibilities: [
            "Laravel Ve React Js İle Web Geliştirme",
            "Html, Css, Javascript İle Web Arayüz Geliştirme",
            "Mobil Uyumlu (Responsive) Web Siteleri",
        ],
    },
    {
        company: "Bionluk",
        from: "Kasım 2020",
        to: "Şu Anda",
        position: "Freelancer - Front-End Developer",
        responsibilities: [
            "Html, Css, Javascript, Bootstrap İle Web Arayüz Geliştirme",
            "Mobil Uyumlu (Responsive) Web Siteleri",
        ],
    },
    {
        company: "Andasis A.Ş.",
        from: "Ağustos 2018",
        to: "Eylül 2018",
        position: "Zorunlu Staj",
        responsibilities: [
            "Yaz Dönemi Stajı - Elektrik Elektronik Mühendisliği",
            "Çalışılan Alanlar: Gömülü Sistemler, Stm32 Arm I2C İle Haberleşme",
        ],
    },
    {
        company: "Başakkent A.Ş.",
        from: "Ağustos 2017",
        to: "Eylül 2017",
        position: "Zorunlu Staj",
        responsibilities: [
            "Yaz Dönemi Stajı - Elektrik Elektronik Mühendisliği",
            "Çalışılan Alanlar: Kompanzasyon, Adp, Ups",
        ],
    },
];

const sertifikalar = [
    {
        name: "C# Ve SQL",
        from: "2020",
        fromName: "IEEE",
        description:
            "IEEE tarafından düzenlenen ve Murat YÜCEDAĞ tarafından verilen C# Ve SQL Workshop programına katılarak katılım belgesini almaya hak kazandım.",
    },
    {
        name: "Genel İngilizce B1",
        from: "2018",
        fromName: "BOSUYAM",
        description:
            "Bozok Üniversitesi, Sürekli Eğitim Uygulama Ve Araştırma Merkezi Tarafından düzenlenen 72 saatlik Genel İngilizce B1 Sertifikası almaya hak kazandım",
    },
    {
        name: "Bozok Üniversitesi",
        from: "2016 - 2020",
        fromName: "Lisans",
        description: "Bölüm : Elektrik Elektronik Mühendisliği",
    },
];

const sosyalMedyaLinkleri = [
    {
        title: "linkedin",
        icon: (
            <IconContext.Provider value={{ size: "1.9rem" }}>
                <TiSocialLinkedinCircular />
            </IconContext.Provider>
        ),
        iconName: "fa fa-linkedin",
        link: info.linkedin,
        classname: "linkedin",
        visibility: false,
    },
    {
        title: "github",
        icon: (
            <IconContext.Provider value={{ size: "1.4rem" }}>
                <FaGithub />
            </IconContext.Provider>
        ),
        iconName: "fa fa-github",
        link: info.github,
        classname: "github",
        visibility: false,
    },
    {
        title: "instagram",
        icon: (
            <IconContext.Provider value={{ size: "1.4rem" }}>
                <FaInstagram />
            </IconContext.Provider>
        ),
        iconName: "fa fa-instagram",
        link: info.instagram,
        classname: "instagram",
        visibility: false,
    },
    {
        title: "facebook",
        icon: (
            <IconContext.Provider value={{ size: "1.5rem" }}>
                <RiFacebookCircleFill />
            </IconContext.Provider>
        ),
        iconName: "fa fa-facebook",
        link: info.facebook,
        classname: "facebook",
        visibility: true,
    },
    {
        title: "email",
        icon: (
            <IconContext.Provider value={{ size: "1.4rem" }}>
                <ImMail4 />
            </IconContext.Provider>
        ),
        iconName: "fa fa-envelope",
        link: `mailto:${info.email}`,
        classname: "email-icon",
        visibility: false,
    },
];

const Data = {
    info: info,
    yeteneklerim: yeteneklerim,
    projeler: projeler,
    tecrubeler: tecrubeler,
    egitim: sertifikalar,
    sosyalMedyaLinkleri: sosyalMedyaLinkleri,
};

export default Data;
