import React from "react";
import Data from "./Data";

const Education = () => {
    const dataEducation = () => {
        return Data.egitim.map((veri, index) => (
            <li key={index}>
                <div className={(index % 2 === 0) ? 'direction-r' : 'direction-l'}>
                    <div className="flag-wrapper"> 
                        <span className="flag">
                            <div className="text-center">
                                {veri.name}
                            </div>
                        </span>
                        <span className="time-wrapper">
                            <span className="time">{veri.from}</span>
                        </span>
                    </div>
                    <div className="desc">
                        <div className="timeline-title category">{veri.fromName}</div>
                        <div>
                            <ul className="tasks text-muted" dir={(index % 2 === 0) ? 'lrl' : 'rtl'}>
                                    <li>{veri.description}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
        ));
    }
    return (
        <>
            <div className="section" id="education">
                <div className="container cc-experience">
                    <div className="h4 text-center mb-4 title">Eğitim</div>
                    <div className="card" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <div className="card-body">
                            <ul className="timeline">
                                {dataEducation()}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Education;