import React from 'react';
import Data from './Data';

const Yetenekler = () => {
    const yeteneklerim = Data.yeteneklerim;

    const populerYetenekler = () => {
        return yeteneklerim.map((yetenek, index) => (
            yetenek.visibility === false ? <div className="col-6 col-md-4 col-lg-3" key={index}>
                 <div className="card text-center p-2 hover-grow">
                    <div className="skill-icon pt-1">
                        {yetenek.icon}
                    </div>
                    <div className="card-body p-1">
                        <p className="card-text font-weight-bolder skill-title text-muted">{yetenek.title}</p>
                    </div>
                </div>
            </div> : null
        ));
    }

    return (
        <React.Fragment>
            <div className="section" id="skill">
                <div className="container">
                    <div className="h4 text-center mb-4 title">Yetenekler</div>
                    <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <div className="card-body">
                            <div className="row justify-content-center">
                                {populerYetenekler()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </React.Fragment>
    );
}

export default Yetenekler;