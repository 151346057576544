import React, { useState } from 'react';
import Data from "./Data";
import { ImFilePdf } from "react-icons/im";
import { IconContext } from "react-icons";

function Contact() {
    const [status, setStatus] = useState();
    return (
        <>
            <div className="section" id="contact">
                <div className="container">
                    <div className="h4 text-center mb-4 title">İletişim</div>
                    <div className="card mb-0 p-5" data-aos="zoom-in">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="card-body">
                                        <form
                                            action={Data.info.formspree}
                                            method="POST"
                                            target="_blank"
                                            id="contact-me-form"
                                            onSubmit={submitForm}
                                        >
                                            <div className="row">
                                                <div className="col-12">
                                                    <div id="success_fail_info" />
                                                </div>

                                                <div className="col-12">
                                                    <div className="group wow fadeInUp" data-wow-delay="0.2s">
                                                        <input type="text"
                                                            name="name"
                                                            id="name"
                                                            required
                                                        />
                                                        <span className="highlight" />
                                                        <span className="bar" />
                                                        <label>İsim Soyisim</label>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="group wow fadeInUp" data-wow-delay="0.3s">
                                                        <input type="text"
                                                            name="email"
                                                            id="email"
                                                            required
                                                        />
                                                        <span className="highlight" />
                                                        <span className="bar" />
                                                        <label>Email</label>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="group wow fadeInUp" data-wow-delay="0.5s">
                                                        <textarea name="message"
                                                            id="message"
                                                            required
                                                        />
                                                        <span className="highlight" />
                                                        <span className="bar" />
                                                        <label>Mesajınızı Yazınız</label>
                                                    </div>
                                                </div>

                                                <div className="col-12 text-center wow fadeInUp"
                                                    data-wow-delay="0.6s">
                                                    {status === "basarili" ? <p>Mesajınız Gönderildi</p> :
                                                        <button className="btn dream-btn">Gönder</button>}
                                                    {status === "hata" && <p>Mesajınız Gönderilemedi</p>}

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="col-md-6 d-none d-md-flex">
                                    <div className="card-body">
                                        {!Data.info.hideAddress &&
                                            <>
                                                <p className="mb-0"><strong
                                                    className="contact-info-header-color">Adres</strong>
                                                </p>

                                                <p className="pb-2">{Data.info.adres}</p>
                                            </>
                                        }
                                        {!Data.info.hideTelefon &&
                                            <>
                                                <p className="mb-0"><strong
                                                    className="contact-info-header-color">Telefon</strong></p>
                                                <p className="pb-2">{Data.info.telefon}</p>
                                            </>
                                        }
                                        <p className="mb-0"><strong
                                            className="contact-info-header-color">Email</strong>
                                        </p>
                                        <p>{Data.info.email}</p>
                                        {Data.info.hideCv &&
                                            <>
                                                <p className="mb-0"><strong
                                                    className="contact-info-header-color">CV</strong></p>
                                                <div>
                                                    <a href={Data.info.cv} className="text-muted"
                                                        target="_blank" rel="noopener noreferrer">
                                                        <IconContext.Provider value={{
                                                            size:
                                                                '1.5rem'
                                                        }}>
                                                            <ImFilePdf />
                                                        </IconContext.Provider>
                                                    </a>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    function submitForm(ev) {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                setStatus('basarili');
            } else {
                setStatus('hata');
            }
        };
        xhr.send(data);
    }
}

export default Contact;